<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <g-chart-custom
          :key="1"
          ref="chart1"
          title="Mash temperature"
          :subtitle="charts.chart1.subtitle"
          type="LineChart"
          :loading="charts.chart1.loading"
          :data="charts.chart1.chartData"
          :options="charts.chart1.chartOptions"
        >
        </g-chart-custom>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import GChartCustom from "@/components/GChartCustom";

export default {
  name: "FermentationReport",
  props: {
    fermentationId: {
      type: String,
      default: ""
    }
  },
  components: {
    GChartCustom
  },
  data: function() {
    return {
      dataSet: [],
      test: 0,
      charts: {
        chart1: {
          loading: false,
          chartData: null,
          chartOptions: {
            width: "100%",
            height: 300,
            //curveType: "function",
            chartArea: {
              width: "80%",
              height: "80%"
            },

            title: "",
            subtitle: "",
            series: {
              0: {
                targetAxisIndex: 0
              }
            },
            vAxis: {
              title: "Temp"
            },
            hAxis: {}
          }
        }
      }
    };
  },
  computed: {},
  updated() {},
  async mounted() {},
  methods: {
    getData() {},
    async drawChart1() {
      let headers = [
        [
          {
            type: "datetime",
            id: "Time",
            label: "Time"
          },
          {
            type: "number",
            id: "Mash temperature",
            label: "Mash temperature"
          }
        ]
      ];

      let data = this.dataSet.map(i => [
        new Date(Date.parse(i.date + " " + i.time)),
        i.temperature
      ]);

      this.charts.chart1.chartData = headers.concat(data);
    },

    async drawCharts() {
      if (!this.dataSet.length) {
        this.charts.chart1.loading = true;

        this.dataSet = await this.$api.get(
          `fermentation/${this.fermentationId}/log-auto-data`
        );

        this.charts.chart1.loading = false;
      }

      if (this.dataSet.length === 0) return;

      this.drawChart1();
    }
  },
  watch: {}
};
</script>

<style></style>
