var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pb-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('g-chart-custom', {
    key: 1,
    ref: "chart1",
    attrs: {
      "title": "Mash temperature",
      "subtitle": _vm.charts.chart1.subtitle,
      "type": "LineChart",
      "loading": _vm.charts.chart1.loading,
      "data": _vm.charts.chart1.chartData,
      "options": _vm.charts.chart1.chartOptions
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }