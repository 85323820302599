<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Overview" active>
          <form>
            <h5>
              Fermentation details
              <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
            </h5>

            <hr />
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.id.id"
                  :value="data.id"
                  :label="controls.id.label"
                  :readonly="controls.id.readonly"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-select
                  :id="controls.product.id"
                  :value="data.product"
                  :label="controls.product.label"
                  :readonly="controls.product.readonly"
                  :options="controls.product.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-select
                  :id="controls.fermentation_container.id"
                  :value="data.fermentation_container"
                  :label="controls.fermentation_container.label"
                  :readonly="controls.fermentation_container.readonly"
                  :options="controls.fermentation_container.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.amount_added.id"
                  :value="data.amount_added"
                  :label="controls.amount_added.label"
                  :readonly="controls.amount_added.readonly"
                  :mode="mode"
                  :required="controls.amount_added.required"
                  @changed="updateField"
                  @keyup="onKeyupField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.amount_fermentation_remaining.id"
                  :value="data.amount_fermentation_remaining"
                  :label="controls.amount_fermentation_remaining.label"
                  :readonly="controls.amount_fermentation_remaining.readonly"
                  :mode="mode"
                  :required="controls.amount_fermentation_remaining.required"
                  @changed="updateField"
                  @keyup="onKeyupField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-date-picker
                  :id="controls.start_date.id"
                  :value-single="data.start_date"
                  :label="controls.start_date.label"
                  :readonly="controls.start_date.readonly"
                  :mode="mode"
                  :required="controls.start_date.required"
                  @changed="updateDateField"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-time-picker
                  :id="controls.start_time.id"
                  :value="data.start_time"
                  :label="'Start time'"
                  :readonly="controls.start_time.readonly"
                  :required="controls.start_time.required"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-time-picker
                  :id="controls.fermentation_time.id"
                  :value="data.fermentation_time"
                  :label="'Fermentation time'"
                  :readonly="controls.fermentation_time.readonly"
                  :required="controls.fermentation_time.required"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-date-picker
                  :id="controls.end_date.id"
                  :value-single="data.end_date"
                  :label="controls.end_date.label"
                  :readonly="controls.end_date.readonly"
                  :mode="mode"
                  :required="controls.end_date.required"
                  @changed="updateDateField"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-time-picker
                  :id="controls.end_time.id"
                  :value="data.end_time"
                  :label="controls.end_time.label"
                  :readonly="controls.end_time.readonly"
                  :required="controls.end_time.required"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.brix_in.id"
                  :value="data.brix_in"
                  :label="controls.brix_in.label"
                  :readonly="controls.brix_in.readonly"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.brix_out.id"
                  :value="data.brix_out"
                  :label="controls.brix_out.label"
                  :readonly="controls.brix_out.readonly"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.created_by.id"
                  :value="data.created_by"
                  :label="controls.created_by.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.modified_by.id"
                  :value="data.modified_by"
                  :label="controls.modified_by.label"
                  :readonly="true"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.created.id"
                  :value="data.created"
                  :label="controls.created.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.modified.id"
                  :value="data.modified"
                  :label="controls.modified.label"
                  :readonly="true"
                />
              </b-col>
            </b-row>
          </form>
          <hr />
          <form-submission-actions
            :mode="mode"
            :loading="{
              save: saveInProgress,
              saveAndView: saveAndViewInProgress
            }"
            :buttons-visibility="{
              previous: $customTable.getPrevKey(dataset, id),
              next: $customTable.getNextKey(dataset, id)
            }"
            @previous-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getPrevKey(dataset, id)
                }
              })
            "
            @next-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getNextKey(dataset, id)
                }
              })
            "
            @save="
              save('tabular').then(response =>
                response ? $router.push({ name: 'Fermentation' }) : false
              )
            "
            @save-and-view="
              save('view').then(response =>
                response
                  ? $router.push({
                      name: 'Fermentation submission',
                      params: { action: 'view', id: response }
                    })
                  : false
              )
            "
            @edit="
              $router.push({
                name: 'Fermentation submission',
                params: { action: 'edit', id: id }
              })
            "
            @back="$router.push($store.getters['router/previousRoute'])"
          />
        </b-tab>
        <b-tab
          :title="controls.tabs.mashings.title"
          v-if="mode !== $constants.FORM_MODE.CREATE && data.product.id"
        >
          <mashing-table
            :mash-id="data.product.id"
            :show-add-button="false"
            @loaded="onMashingsTableLoad"
          ></mashing-table>
        </b-tab>
        <b-tab
          :title="controls.tabs.distillations.title"
          v-if="mode !== $constants.FORM_MODE.CREATE && data.product.id"
        >
          <distillation-table
            :mash-id="data.product.id"
            :fermentation-id="id"
            :show-add-button="+data.amount_fermentation_remaining > 0"
            @loaded="onDistillationsTableLoad"
          ></distillation-table>
        </b-tab>
        <b-tab
          :title="controls.tabs.report.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
          @click="onFermentationReportTabClick"
        >
          <fermentation-report
            ref="fermentation-report"
            :fermentation-id="this.id"
          >
          </fermentation-report>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import MashingTable from "@/views/Production/Mashing/MashingTable.vue";
import DistillationTable from "@/views/Production/Distillation/DistillationTable.vue";

import FermentationReport from "@/views/Production/Fermentation/FermentationAutoLogReport.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "FermentationSubmissionForm",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    },
    mashId: {
      type: String,
      default: null
    }
  },
  components: {
    MashingTable,
    DistillationTable,
    FermentationReport
  },
  data: function() {
    return {
      dataset: {
        name: "fermentation"
      },
      baseApiUrl: "fermentation",
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        tabs: {
          mashings: {
            title: "Mashings"
          },
          distillations: {
            title: "Distillations"
          },
          report: {
            title: "Report"
          }
        },
        id: {
          id: "input:ID",
          label: "Fermentation ID",
          changed: false,
          readonly: true
        },
        product: {
          id: "select:product",
          label: "Product",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        fermentation_container: {
          id: "select:fermentation_container",
          label: "Fermentation container",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },

        start_date: {
          id: "datepicker:start_date",
          label: "Start Date",
          changed: false,
          readonly: false,
          required: true
        },
        start_time: {
          id: "timepicker:start_time",
          label: "Start time",
          changed: false,
          readonly: false,
          required: true
        },
        fermentation_time: {
          id: "timepicker:fermentation_time",
          label: "Fermentation time",
          changed: false,
          readonly: false
        },
        end_date: {
          id: "datepicker:end_date",
          label: "End Date",
          changed: false,
          readonly: false,
          required: false
        },
        end_time: {
          id: "timepicker:end_time",
          label: "End time",
          changed: false,
          readonly: false,
          required: true
        },
        amount_added: {
          id: "input:amount_added",
          label: "Amount Added",
          changed: false,
          readonly: false,
          required: true
        },
        amount_fermentation_remaining: {
          id: "input:amount_fermentation_remaining",
          label: "Amount Fermentaion Remaining",
          changed: false,
          readonly: true,
          required: false
        },
        brix_in: {
          id: "input:brix_in",
          label: "Brix In",
          changed: false,
          readonly: false
        },
        brix_out: {
          id: "input:brix_out",
          label: "Brix Out",
          changed: false,
          readonly: false
        },

        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: true
        },
        modified: {
          id: "input:modified",
          label: "Modified",
          changed: false,
          readonly: true
        },
        created_by: {
          id: "input:created_by",
          label: "Created By",
          changed: false,
          readonly: true
        },
        modified_by: {
          id: "input:modified_by",
          label: "Modified By",
          changed: false,
          readonly: true
        }
      },
      data: {
        id: "",
        product: {
          id: "",
          label: ""
        },
        fermentation_container: {
          id: "",
          label: ""
        },
        start_date: "",
        start_time: "",
        mashing_time: "",
        end_date: "",
        end_time: "",
        amount_added: "",
        amount_fermentation_remaining: "",
        brix_in: "",
        brix_out: "",
        created_by: "",
        modified_by: "",
        created: "",
        modified: ""
      },
      selectAll: false,
      selectedRows: [],
      dropdowns: {},
      dblclick: undefined,
      serverFilter: undefined
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    async initialize() {
      if (this.action === "create")
        this.mode = this.$constants.FORM_MODE.CREATE;

      if (this.action === "edit") this.mode = this.$constants.FORM_MODE.EDIT;

      if (this.action === "view") this.mode = this.$constants.FORM_MODE.VIEW;

      if (this.mode !== this.$constants.FORM_MODE.CREATE)
        this.controls.amount_added.readonly = true;

      this.data.id = this.id;

      this.loadDictionaries();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.loadData(this.id);
      }
    },

    async loadDictionaries() {
      let self = this;

      this.isLoading = true;

      const fermentation_containers = async () => {
        let response = await this.$api.get(
          "dictionaries/fermentation-containers"
        );
        self.controls.fermentation_container.options = response.map(u => ({
          id: u.id,
          label: `${u.name} [Available ${u.capacity - u.amount_remaining}L]`,
          capacity: +u.capacity,
          amount_remaining: +u.amount_remaining
        }));
      };

      const products = async () => {
        let response = await this.$api.post("dictionaries/mashes", {
          not_empty: self.mode === this.$constants.FORM_MODE.CREATE
        });

        self.controls.product.options = response.map(u => ({
          id: u.id,
          label: u.name,
          amount_remaining: +u.amount_remaining
        }));

        if (self.mashId) {
          self.data.product = self.controls.product.options.find(
            u => u.id === self.mashId
          );
        }
      };

      await Promise.all([products(), fermentation_containers()]);

      this.isLoading = false;
    },
    edit() {},

    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let method = "";

      let url = this.baseApiUrl;

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
        url = this.baseApiUrl;
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = "put";
        url = `${this.baseApiUrl}/${this.data.id}`;
      }

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    onKeyupField(e) {
      if (e.id === "input:amount_added") {
        this.data.amount_added = e.val;
        this.checkContainerCapacity();
      }
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateField(field, value, mode) {
      this.$form.updateField(this.baseApiUrl, this, field, value, mode);
    },
    async updateSelect(id, value) {
      this.$form.updateField(this.baseApiUrl, this, id, value, this.mode);

      if (id === "product") {
        this.data.amount_added = this.data.product.amount_remaining;
      }
      if (id === "fermentation_container") {
        let container = this.controls.fermentation_container.options.find(
          c => c.id === value.id
        );

        this.data.fermentation_container = container;

        if (
          this.data.fermentation_container.capacity -
            this.data.fermentation_container.amount_remaining <
          this.data.fermentation_container.capacity
        ) {
          await this.$form.makeToastWarning(
            "Be aware! You have selected not empty container!"
          );
        }
        this.checkContainerCapacity();
      }
    },
    async checkContainerCapacity() {
      let totalProductAmount =
        this.data.fermentation_container.amount_remaining +
        parseInt(this.data.amount_added);

      if (totalProductAmount > this.data.fermentation_container.capacity) {
        await this.$form.makeToastWarning(
          `Attention! Total product amount ${totalProductAmount}L will be over container's capacity ${this.data.fermentation_container.capacity}L!`
        );
      }
    },
    async loadData(id) {
      let self = this;

      this.isLoading = true;

      try {
        let response = await this.$api.get(`${this.baseApiUrl}/${id}`);

        this.isLoading = false;

        if (self.$_.isEmpty(response)) return;

        this.data.id = response["ID"];

        this.data.product = {
          id: response["Product ID"],
          label: response["Product Name"]
        };
        this.data.fermentation_container = {
          id: response["Container ID"],
          label: response["Container Number"]
        };

        this.data.amount_added = response["Amount"];
        this.data.amount_fermentation_remaining =
          response["Amount Fermentation Remaining"];
        this.data.start_date = response["Start Date"];
        this.data.start_time = response["Start Time"];

        this.data.fermentation_time = response["Fermentation Time"];

        this.data.end_date = response["End Date"];
        this.data.end_time = response["End Time"];

        this.data.created = response["Created"];
        this.data.modified = response["Modified"];

        this.data.created_by = response["Created By"];
        this.data.modified_by = response["Modified By"];

        this.data.brix_in = response["Brix In"];
        this.data.brix_out = response["Brix Out"];
      } catch (error) {
        console.log(error);

        this.$form.makeToastError(error);
      } finally {
        this.isLoading = false;
      }
    },
    onMashingsTableLoad(count) {
      if (count > 0) this.controls.tabs.mashings.title = `Mashings (${count})`;
    },
    onDistillationsTableLoad(count) {
      if (count > 0)
        this.controls.tabs.distillations.title = `Distillations (${count})`;
    },
    onFermentationReportTabClick() {
      this.$refs["fermentation-report"].drawCharts();
    }
  }
};
</script>

<style scoped></style>
